import React from 'react';
import styled from "styled-components";

function Footer() {
  return (
    <FooterDiv>
        © 2022 all right reserved - &nbsp;<a href="https://bonson-web-solutions.com">Bonson Web Solutions</a>
    </FooterDiv>
  );
}

const FooterDiv = styled.footer`
    display: flex;
    justify-content: center;
    margin: 2rem 0rem;
    font-weight: bold;
`;

export default Footer;